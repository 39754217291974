import { attachDynamicEvent } from '~/common/helpers/dom'
import { processFormSubmission } from './common'

/*
  A payment window is a payment processor where the process is started by the user's browser submitting to the payment
  provider's website. When the user submits the checkout, we generate a form, put it on page, then have the browser
  submit it. Then the payment processor handles everything else.
*/

function submitIfWithMobilePay(event) {
  if (getSelectedQuickpayLinkProcessorOption()) {
    submitWithMobilePay(event)
  }
}

async function submitWithMobilePay(event) {
  event.preventDefault()

  let submitButton = event.target
  let form = submitButton.closest('form')

  processFormSubmission(form, {
    onSuccess: (json) => {
      window.top.location.href = json.redirect
    },
    onError: (json) => {
      console.log('MobilePay submit received error: ' + JSON.stringify(json))
      alert(json.error || "We're sorry, we could not process your order.")
    },
  })
}

function getSelectedQuickpayLinkProcessorOption() {
  return document.querySelector(
    '.cart-checkout-payment .payment-processor-option-mobilepay input[type=radio]:checked, ' +
      ".cart-checkout-payment input[type=hidden][name='payment_method'].payment-processor-option-mobilepay"
  )
}

attachDynamicEvent(
  '.cart-checkout-payment .submit-payment',
  'click',
  submitIfWithMobilePay
)
