import { onPaymentMethodChange, showLoading, hideLoading } from '../common'
import {
  handleValidationFail,
  initializeNmi,
  submitWithSavedNmi,
  submitWithNewNmi,
  chooseNonNmi,
} from './nmi_common'
import './nmi.scss'
import { attachDynamicEvent } from '~/common/helpers/dom'
import { debounce } from 'throttle-debounce'

let scope = '.nmi_credit_card'

// Set up the UI for a new Nmi payment method
function initializeForNmiPaymentProcessor(paymentProcessorId) {
  if (!document.querySelector(scope).querySelector('.nmi_fields')) {
    document
      .querySelector('.submit-payment')
      .closest('form')
      .addEventListener('submit', (e) => {
        submitWithSavedNmi(e)
      })

    return
  }
  showLoading()
  initializeNmi(scope, paymentProcessorId)
    .then(
      () =>
        new Promise((resolve) => {
          // Needs to be visible before configure is called otherwise the iframes have height 0.
          document.querySelector(scope).style.display = 'block'
          document
            .querySelector(scope)
            .querySelector('.nmi_fields').style.display = 'block'
          window.CollectJS.configure({
            paymentSelector: '.submit-payment',
            fieldsAvailableCallback: resolve,
            callback: submitWithNewNmiCreditCard,
            validationCallback: handleCreditCardValidationFail,
            variant: 'inline',
            fields: {
              cvv: {
                selector: '#nmi-cvv',
                placeholder: 'CVV',
              },
              ccnumber: {
                selector: '#nmi-ccnumber',
                placeholder: 'Card number',
              },
              ccexp: {
                selector: '#nmi-ccexp',
                placeholder: 'MM / YY',
              },
            },
          })
        })
    )
    .then(() => {
      // Just something for the test to wait for.
      document
        .getElementById('CollectJSInlineccnumber')
        .classList.add('nmi-inputs-ready')
      document.querySelector(scope).style.display = ''
      hideLoading()
    })
}

const debouncedInitialization = debounce(500, initializeForNmiPaymentProcessor)

function handleCreditCardValidationFail(field, status, message) {
  handleValidationFail(scope, field, status, message)
}

function chooseNewNmiCreditCard(event) {
  debouncedInitialization(event.target.value)
}

function chooseMaybeNonNmiCreditCard(event) {
  let choseOtherNmi = event.target.id.match(/-ach$/)
  if (
    choseOtherNmi ||
    event.target.closest('.payment-processor-option-nmi') == null
  ) {
    // If another NMI option was chosen, collect.js will be reconfigured. If it's not NMI, then we need to reconfigure
    // it to remove its event listeners.
    chooseNonNmiCreditCard(!choseOtherNmi)
  }
}

function chooseNonNmiCreditCard(reconfigureCollectJS) {
  chooseNonNmi(scope, reconfigureCollectJS)
}

async function submitWithNewNmiCreditCard(response) {
  let data = {
    payment_token: response.token,
    cc_last4: response.card.number.slice(-4),
    cc_exp: response.card.exp,
    cc_type: response.card.type,
  }

  submitWithNewNmi(scope, response, data)
}

function getSelectedNmiCreditCardProcessorOption() {
  return document.querySelector(
    `.cart-checkout-payment .payment-processor-option-nmi input[type=radio][id$='-card']:checked, ` +
      `.cart-checkout-payment ${scope} input[type=hidden][name='payment_method'].payment-processor-option-nmi`
  )
}

function getSelectedSavedNmiOption() {
  return document.querySelector(
    '.cart-checkout-payment .payment-method-option-nmi input[type=radio]:checked'
  )
}

function submitIfWithSavedNmi(event) {
  if (getSelectedSavedNmiOption()) {
    submitWithSavedNmi(event)
  }
}

onPaymentMethodChange(chooseMaybeNonNmiCreditCard)
attachDynamicEvent(
  ".cart-checkout-payment .payment-processor-option-nmi input[type=radio][id$='-card']",
  'change',
  chooseNewNmiCreditCard
)
// For new NMI, the collect.js handles the event, so we just need to handle saved payment methods. We are handling
// saved ACH here as well, as the process is the same.
attachDynamicEvent('form.cart-checkout-payment', 'submit', submitIfWithSavedNmi)
attachDynamicEvent(
  'form.cart-checkout-payment .submit-payment',
  'click',
  () => {
    // Show loading state on submit - collect.js handles the form submit itself so we add this as a click.
    if (getSelectedNmiCreditCardProcessorOption()) {
      showLoading()
    }
  }
)
