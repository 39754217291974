import { processFormSubmission } from './common'
import { attachDynamicEvent } from '~/common/helpers/dom'

function submitWithAffirm(event) {
  event.preventDefault()
  event.stopPropagation()

  let form = getSelectedAffirmOption().closest('form')

  processFormSubmission(form, {
    onSuccess: submitToAffirm,
  })

  return false
}

function submitToAffirm(data) {
  window.affirm.checkout({
    merchant: {
      user_confirmation_url: data.redirect_url,
      user_cancel_url: window.location.href,
      public_api_key: data.public_key,
    },
    shipping: {
      name: {
        full: data.name,
      },
      address: {
        line1: data.address1,
        line2: data.address2,
        city: data.city,
        state: data.state,
        zipcode: data.zip,
        country: data.country,
      },
    },
    items: data.items,
    currency: data.currency,
    tax_amount: data.tax_cents,
    total: data.total_cents,
    user_confirmation_url_action: 'GET',
  })
  window.affirm.checkout.open()
}

function getSelectedAffirmOption() {
  return document.querySelector(
    `.cart-checkout-payment .payment-processor-option-affirm input:checked`
  )
}

function submitIfWithAffirm(event) {
  if (getSelectedAffirmOption()) {
    submitWithAffirm(event)
  }
}

attachDynamicEvent('form.cart-checkout-payment', 'submit', submitIfWithAffirm)
attachDynamicEvent(
  'form.cart-checkout-payment .submit-payment',
  'click',
  submitIfWithAffirm
)
