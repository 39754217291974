import { onPaymentMethodChange, showLoading, hideLoading } from '../common'
import {
  handleValidationFail,
  initializeNmi,
  submitWithNewNmi,
  chooseNonNmi,
} from './nmi_common'
import './nmi.scss'
import { attachDynamicEvent } from '~/common/helpers/dom'
import { debounce } from 'throttle-debounce'

let scope = '.nmi_ach'

// Set up the UI for a new Nmi payment method
function initializeForNmiPaymentProcessor(paymentProcessorId) {
  showLoading()
  initializeNmi(scope, paymentProcessorId)
    .then(
      () =>
        new Promise((resolve) => {
          // Needs to be visible before configure is called otherwise the iframes have height 0.
          document.querySelector(scope).style.display = 'block'
          document
            .querySelector(scope)
            .querySelector('.nmi_fields').style.display = 'block'
          window.CollectJS.configure({
            paymentSelector: '.submit-payment',
            fieldsAvailableCallback: resolve,
            callback: submitWithNewNmiAch,
            validationCallback: handleAchValidationFail,
            variant: 'inline',
            fields: {
              checkname: {
                selector: '#nmi-checkname',
                placeholder: 'Account Holder Name',
              },
              checkaccount: {
                selector: '#nmi-checkaccount',
                placeholder: 'Account Number',
              },
              checkaba: {
                selector: '#nmi-checkaba',
                placeholder: 'Routing Number',
              },
            },
          })
        })
    )
    .then(() => {
      // Just something for the test to wait for.
      document
        .getElementById('CollectJSInlinecheckname')
        .classList.add('nmi-inputs-ready')
      document.dispatchEvent(new CustomEvent('nmi:fieldsShown'))
      hideLoading()
    })
}

const debouncedInitialization = debounce(500, initializeForNmiPaymentProcessor)

function handleAchValidationFail(field, status, message) {
  handleValidationFail(scope, field, status, message)
}

function chooseNewNmiAch(event) {
  debouncedInitialization(event.target.value)
}

function chooseMaybeNonNmiAch(event) {
  let choseOtherNmi = event.target.id.match(/-card$/)
  if (
    choseOtherNmi ||
    event.target.closest('.payment-processor-option-nmi') == null
  ) {
    // If another NMI option was chosen, collect.js will be reconfigured. If it's not NMI, then we need to reconfigure
    // it to remove its event listeners.
    chooseNonNmiAch(!choseOtherNmi)
  }
}

function chooseNonNmiAch() {
  chooseNonNmi(scope)
}

async function submitWithNewNmiAch(response) {
  let data = {
    payment_token: response.token,
    ach_last4: response.check.account.slice(-4),
  }

  submitWithNewNmi(scope, response, data)
}

function getSelectedNmiAchProcessorOption() {
  return document.querySelector(
    `.cart-checkout-payment .payment-processor-option-nmi input[type=radio][id$='-ach']:checked, ` +
      `.cart-checkout-payment input[type=hidden][name='payment_method'].payment-processor-option-nmi`
  )
}

onPaymentMethodChange(chooseMaybeNonNmiAch)
attachDynamicEvent(
  ".cart-checkout-payment .payment-processor-option-nmi input[type=radio][id$='-ach']",
  'change',
  chooseNewNmiAch
)
// Saved ACH acts just like saved credit card, so we leave it up to the credit card JS to handle everything for saved payment methods.
attachDynamicEvent(
  'form.cart-checkout-payment .submit-payment',
  'click',
  () => {
    if (getSelectedNmiAchProcessorOption()) {
      showLoading()
    }
  }
)
