/*
  Handles manual payment methods (cash, bank transfers, etc.), where the purchase gets marked as pending until the admin
  marks the payment as received. Also handles cases where no payment is required (free product).
 */

import { attachDynamicEvent } from '~/common/helpers/dom'
import { processFormSubmission } from './common'

function submitIfWithManual(event) {
  if (getSelectedManualOption()) {
    submitWithManual(event)
  }
}

function submitIfNoPaymentMethod(event) {
  if (
    document.querySelector('.cart-checkout-payment') &&
    !document.querySelector('.payment-method-container') &&
    !document.querySelector(
      '.cart-checkout-payment input[name*=payment_method_id][type=radio]'
    )
  ) {
    submitWithManual(event)
  }
}

async function submitWithManual(event) {
  event.preventDefault()

  let submitButton = event.target
  let form = submitButton.closest('form')

  processFormSubmission(form, {
    onSuccess: (json) => {
      location.href = json.callback_url
    },
  })
}

function getSelectedManualOption() {
  return document.querySelector(
    '.cart-checkout-payment .payment-processor-option-manual input[type=radio]:checked, ' +
      ".cart-checkout-payment input[type=hidden][name='payment_method'].payment-processor-option-manual"
  )
}

attachDynamicEvent(
  '.cart-checkout-payment .submit-payment',
  'click',
  submitIfWithManual
)
attachDynamicEvent(
  '.cart-checkout-payment .submit-payment',
  'click',
  submitIfNoPaymentMethod
)
