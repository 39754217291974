import { appendCsrfParams } from '~/common/helpers/csrf'
import { getBaseCartPath, hideLoading } from '~/site/pages/cart/common'
import { processFormSubmission } from './common'

/**
 * Initializes the NMI payment form by setting up required fields and loading the NMI tokenization script.
 * param {string} scopeSelector - The CSS selector for the scope in which the NMI fields are present.
 * param {string} processorId   - The payment processor ID for NMI, with an optional 'pp-' prefix.
 */
export function initializeNmi(scopeSelector, processorId) {
  // Switching away from NMI and back to it reinitializes the fields, so we want to clear the errors too.
  clearErrorElement(scopeSelector)

  let paymentProcessorId = processorId.replace(/pp-/, '')

  let data = new URLSearchParams()
  data.append('payment_method[payment_processor_id]', paymentProcessorId)
  appendCsrfParams(data)

  return fetch(getBaseCartPath() + '/nmi_setup', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  })
    .then((response) => response.json())
    .then(
      (json) =>
        new Promise((resolve) => {
          let script = document.createElement('script')
          script.src = 'https://secure.nmi.com/token/Collect.js'
          script.setAttribute('data-tokenization-key', json.public_security_key)
          configureFieldPlaceholers(script, scopeSelector)
          script.addEventListener('load', resolve)
          document
            .querySelector(scopeSelector)
            .querySelector('.nmi_fields')
            .insertBefore(script, null)
        })
    )
}

// Using style-sniffer we can define selectors to style
// NMI field placeholders that will be created and placed
// before NMI creates and injects the actual fields (that usually requires some seconds)
// This way the UI is smoother
// https://support.nmi.com/hc/en-gb/articles/360009713318-Collect-js-Tokenization-With-Direct-Connect
function configureFieldPlaceholers(script, selector) {
  script.setAttribute('data-style-sniffer', true)
  if (selector == '.nmi_credit_card') {
    script.setAttribute('data-field-ccnumber-selector', '#nmi-ccnumber')
    script.setAttribute('data-field-ccexp-selector', '#nmi-ccexp')
    script.setAttribute('data-field-cvv-selector', '#nmi-cvv')
  } else if (selector == '.nmi_ach') {
    script.setAttribute('data-field-checkname-selector', '#nmi-checkname')
    script.setAttribute('data-field-checkaccount-selector', '#nmi-checkaccount')
    script.setAttribute('data-field-checkaba-selector', '#nmi-checkaba')
  }
}

export function handleValidationFail(scopeSelector, field, status, message) {
  if (
    !document.querySelector(scopeSelector).querySelector(`[id$='${field}']`)
  ) {
    // If you put in an invalid value then switch between NMI Credit Card and NMI ACH, it fires the validation on both.
    // Don't put a validation message for something that's not in the current scope.
    console.log(
      `Received validation for field ${field} but that's not present within ${scopeSelector}.`
    )
    return
  }

  let errorElement = getErrorElement(scopeSelector)
  let fieldError = errorElement.querySelector('.error-message--' + field)
  if (status) {
    if (fieldError) {
      fieldError.remove()
    }
    if (!errorElement.querySelector('.error-message:not(:empty)')) {
      errorElement.style.display = 'none'
      errorElement.classList.add('hidden') // hidden have !important, 'block' does not work in some cases
    }
    return
  }

  if (!fieldError) {
    fieldError = document.createElement('div')
    fieldError.setAttribute('class', 'error-message error-message--' + field)
    errorElement.appendChild(fieldError)
  }

  if (message == 'Field is empty') {
    switch (field) {
      case 'ccnumber':
        message = 'Credit card number is required'
        break
      case 'ccexp':
        message = 'Credit card expiry is required'
        break
      case 'cvv':
        message = 'Credit card CVV is required'
        break
      case 'checkname':
        message = 'Account holder name is required'
        break
      case 'checkaccount':
        message = 'Account number is required'
        break
      case 'checkaba':
        message = 'Routing number is required'
        break
    }
  }
  fieldError.innerText = message

  showErrorElement(scopeSelector)
  hideLoading()
}

function getErrorElement(scopeSelector) {
  return document.querySelector(scopeSelector).querySelector('.nmi_errors')
}

function showErrorElement(scopeSelector) {
  let errorElement = getErrorElement(scopeSelector)
  errorElement.style.display = 'block'
  errorElement.classList.remove('hidden') // hidden have !important, 'block' does not work in some cases
}

function clearErrorElement(scopeSelector) {
  let error = getErrorElement(scopeSelector)
  error.style.display = 'none'
  error.innerHTML = ''
}

export async function submitWithSavedNmi(event) {
  event.preventDefault()

  let form = event.target

  hideLoaderIfAgreement()

  processFormSubmission(form, {
    onSuccess: (json) => {
      location.href = json.callback_url
    },
  })
}

export async function submitWithNewNmi(scopeSelector, response, data) {
  let form = document.querySelector('.cart-checkout-payment')

  hideLoaderIfAgreement()
  getErrorElement(scopeSelector).style.display = 'none'

  processFormSubmission(form, {
    getFormData: () => {
      let formData = new FormData(form)
      for (let key in data) {
        formData.append(key, data[key])
      }
      return formData
    },
    onSuccess: (json) => {
      location.href = json.callback_url
    },
    onError: (json) => {
      getErrorElement(scopeSelector).innerText =
        'The payment gateway rejected the transaction: ' + json.error
      showErrorElement(scopeSelector)
    },
  })
}

export function chooseNonNmi(scopeSelector, reconfigureCollectJS) {
  let nmiFields = document.querySelector(scopeSelector)
  if (nmiFields) {
    nmiFields.style.display = 'none'
    if (reconfigureCollectJS && window.CollectJS) {
      // Call configure again so we unbind from the submit button.
      window.CollectJS.configure({
        paymentSelector: '.disable-nmi-callbacks',
      })
    }
    nmiFields
      .querySelectorAll('iframe.CollectJSInlineIframe')
      .forEach((el) => el.remove())
  }
}

function hideLoaderIfAgreement() {
  const agreementsController = document.querySelector(
    '[data-controller="cart--agreements"]'
  )
  if (agreementsController) {
    hideLoading()
  }
}
