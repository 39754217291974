import $ from 'jquery'
import { onPaymentMethodChange } from '../common'
import './spreedly.scss'
import { attachDynamicEvent } from '~/common/helpers/dom'
import { processFormSubmission } from './common'

/*
  With Spreedly, we create the payment method server-side, respond with some attributes, then have the user submit a
  form to Spreedly. In this request, we specify a return URL, which is where Spreedly will direct the user. This return
  URL includes a token from Spreedly which we can then use to make the charge.
*/

function submitIfWithNewSpreedly(event) {
  if (getSelectedSpreedlyProcessorOption()) {
    submitWithNewSpreedly(event)
  }
}

function submitIfWithSavedSpreedly(event) {
  if (getSelectedSavedSpreedlyOption()) {
    submitWithSavedSpreedly(event)
  }
}

function getErrorElement() {
  return document.getElementById('spreedly_errors')
}

async function submitWithNewSpreedly(event) {
  event.preventDefault()

  let submitButton = event.target
  let form = submitButton.closest('form')

  getErrorElement()?.classList?.add('hidden')

  processFormSubmission(form, {
    onSuccess: submitToSpreedly,
    onError: (error) => {
      let errorElement = getErrorElement()
      errorElement.querySelector('.error-message').innerText = error.message
      errorElement?.classList?.remove('hidden') // hidden have !important, 'block' does not work in some cases
    },
  })
}

// Attempt to charge server-side. If that fails, go client-side (for 3DS).
async function submitWithSavedSpreedly(event) {
  event.preventDefault()

  let submitButton = event.target
  let form = submitButton.closest('form')

  processFormSubmission(form, {
    onSuccess: (json) => {
      location.href = json.redirect
    },
  })
}

function submitToSpreedly(data) {
  Object.entries(data.spreedly_hidden_form).forEach(([key, value]) => {
    document.querySelector(`#spreedly_hidden_form input[name="${key}"]`).value =
      value
  })

  document.querySelector(
    '#spreedly_hidden_form input[name="credit_card[number]"]'
  ).value = document
    .getElementById('spreedly_payment_method_card_number')
    .value.replaceAll(/\s/g, '')
  document.querySelector(
    '#spreedly_hidden_form input[name="credit_card[verification_value]"]'
  ).value = document.getElementById('spreedly_payment_method_cvc').value
  document.querySelector(
    '#spreedly_hidden_form input[name="credit_card[year]"]'
  ).value = document.getElementById(
    'spreedly_payment_method_expiration_year'
  ).value
  document.querySelector(
    '#spreedly_hidden_form input[name="credit_card[month]"]'
  ).value = document.getElementById(
    'spreedly_payment_method_expiration_month'
  ).value

  document.querySelector('#spreedly_hidden_form').submit()
}

function getSelectedSpreedlyProcessorOption() {
  return document.querySelector(
    '.cart-checkout-payment .payment-processor-option-spreedlycore input[type=radio]:checked, ' +
      ".cart-checkout-payment input[type=hidden][name='payment_method'].payment-processor-option-spreedlycore"
  )
}

function getSelectedSavedSpreedlyOption() {
  return document.querySelector(
    '.cart-checkout-payment .payment-method-option-spreedlycore input[type=radio]:checked'
  )
}

let loadedCreditCardFormatter = false
async function loadCreditCardFormatter() {
  if (loadedCreditCardFormatter) return
  loadedCreditCardFormatter = true

  let ccField = document.querySelector(
    '.cart-checkout-payment #spreedly_payment_method_card_number'
  )
  if (ccField) {
    await import(
      '../../../../../assets/javascripts/system_public/vendor/jquery-payment'
    )
    $(ccField).payment('formatCardNumber')
  }
}

function chooseNewSpreedly(_event) {
  loadCreditCardFormatter()
  document.getElementById('spreedly_credit_card_fields').style.display = 'block'
  document
    .querySelectorAll('#spreedly_credit_card_fields :disabled')
    .forEach((el) => (el.disabled = false))
}

function chooseMaybeNonSpreedly(event) {
  if (event.target.closest('.payment-processor-option-spreedlycore') == null) {
    getErrorElement()?.classList?.add('hidden')

    let ccFields = document.getElementById('spreedly_credit_card_fields')
    if (ccFields) {
      ccFields.style.display = 'none'
      ccFields
        .querySelectorAll('input, select, textarea')
        .forEach((el) => (el.disabled = true))
    }
  }
}

function toggleCvcInstructions(event) {
  let cvcInstructions = document.getElementById('cvc-code-instructions')
  if (cvcInstructions.style.display == 'block') {
    cvcInstructions.style.display = 'none'
  } else {
    cvcInstructions.style.display = 'block'
  }
  event.preventDefault()
}

onPaymentMethodChange(chooseMaybeNonSpreedly)
attachDynamicEvent(
  '.cart-checkout-payment .payment-processor-option-spreedlycore input[type=radio]',
  'change',
  chooseNewSpreedly
)
attachDynamicEvent(
  '.cart-checkout-payment [data-behavior="cvc-code"]',
  'click',
  toggleCvcInstructions
)
attachDynamicEvent(
  '.cart-checkout-payment #cvc-code-instructions',
  'click',
  toggleCvcInstructions
)
attachDynamicEvent(
  '.cart-checkout-payment .submit-payment',
  'click',
  submitIfWithNewSpreedly
)
attachDynamicEvent(
  '.cart-checkout-payment .submit-payment',
  'click',
  submitIfWithSavedSpreedly
)
