import { attachDynamicEvent } from '~/common/helpers/dom'
import { processFormSubmission } from './common'

/*
  Paypal's process is started by the user's browser submitting to the payment provider's website. When the user submits
  the checkout, we generate a form, put it on page, then have the browser submit it. Then the payment processor handles
  everything else.

  There are no usable saved payment methods, so we only have to deal with new ones.
*/

function submitIfWithNewPaypal(event) {
  if (getSelectedPaypalProcessorOption()) {
    submitWithNewPaypal(event)
  }
}

async function submitWithNewPaypal(event) {
  event.preventDefault()

  let submitButton = event.target
  let form = submitButton.closest('form')

  processFormSubmission(form, {
    onSuccess: (json) => {
      document.getElementById('payment_window').innerHTML = json.payment_window
      const form = document.querySelector('#payment_window form')
      form.target = '_top'
      form.submit()
    },
  })
}

function getSelectedPaypalProcessorOption() {
  return document.querySelector(
    '.cart-checkout-payment .payment-processor-option-paypal input[type=radio]:checked, ' +
      ".cart-checkout-payment input[type=hidden][name='payment_method'].payment-processor-option-paypal"
  )
}

attachDynamicEvent(
  '.cart-checkout-payment .submit-payment',
  'click',
  submitIfWithNewPaypal
)
